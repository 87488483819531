import * as React from "react"
import { Link } from "gatsby"
import striptags from "striptags"
import truncate from "truncate"
import YEARS from "../../content/years.json"
import DETAILS from "../../content/details.json"

import Layout from "../components/layout"
import Seo from "../components/seo"

const YearsPage = () => (
  <Layout>
    <Seo title="Timeline" />
    <div className="container">
      <h1 style={{ marginTop: "0px" }}>Years</h1>
      <div className="masonry-row row">
        {YEARS.map(year => (
          <div class="masonry-item">
            <div class="well well-sm">
              {year} - {DETAILS[year].title}
              <small>
                {truncate(striptags(DETAILS[year].html), 150)}
                <br />
                <Link to={`/year/${year}`}>Read More &raquo;</Link>
              </small>
            </div>
          </div>
        ))}
      </div>
    </div>
  </Layout>
)

export default YearsPage
